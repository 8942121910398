import React from 'react';
import ReactDOM from 'react-dom/client';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import 'style/style.css'
import Navigation from './navandfoot/Navigation';
import Welcome from './siteContent/Welcome';
import Club from './siteContent/Club';
import Training from './siteContent/Training';
import Contact from './siteContent/Contact';
import Spee from './siteContent/Spee';
import Gallery from './siteContent/Gallery';
import Sponsors from './siteContent/Sponsors';

import Footer from './navandfoot/Footer';
import reportWebVitals from './reportWebVitals';
import Auth from './authentication/Auth';
import UserMgmt from './adminComponents/UserMgmt';

import getLanguageSet from './helpers/language';
import {IntlProvider} from 'react-intl';
import { checkTokenValidity } from './adminComponents/token';
import AdminControls from './adminComponents/AdminControls';
import "./atoms/EditContent.css"

const root = ReactDOM.createRoot(document.getElementById('content'));
const locale = navigator.language;
let lang = getLanguageSet(locale);
const adminComponentsVisibleDefault = false;
const deleteModeDefault = false;

const consoleWarn = console.error;
const SUPPRESSED_WARNINGS = ['A component is ', 'cannot appear', 'You provided a `checked` prop to a form field without an `onChange` handler.'];

console.error = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
        consoleWarn(msg, ...args);
    }
};

function App() {
  const [adminComponentsVisible, setAdminComponentVisibility] = React.useState(adminComponentsVisibleDefault);
  const [deleteMode, setDeleteMode] = React.useState(deleteModeDefault)
  const [validity, setValidity] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await checkTokenValidity();
        setValidity(result);
      } catch (error) {
        console.error("Fehler beim Überprüfen des Tokens:", error);
        setValidity(false); 
      }
    };

    fetchData();
  }, []); 

  return (
    <React.StrictMode>
      <IntlProvider locale={locale} messages={lang}>
        {(validity) ?
          <>
            <AdminControls adminComponentsVisible={adminComponentsVisible} setAdminComponentVisibility={setAdminComponentVisibility} deleteMode={deleteMode} setDeleteMode={setDeleteMode} />
            <Navigation />
            <div className="ContentArea">
              <BrowserRouter>
                <Routes>
                  <Route path="/club" element={<Club page={"page_club"} adminComponentsVisible={adminComponentsVisible} deleteMode={deleteMode} />}exact  />
                  <Route path="/contact" element={<Contact page={"page_contact"} adminComponentsVisible={adminComponentsVisible} deleteMode={deleteMode} />}exact  />
                  <Route path="/gallery" element={<Gallery page={"page_gallery"} adminComponentsVisible={adminComponentsVisible} deleteMode={deleteMode} />}exact  />
                  <Route path="/spee" element={<Spee page={"page_spee"} adminComponentsVisible={adminComponentsVisible} deleteMode={deleteMode} />}exact  />
                  <Route path="/sponsors" element={<Sponsors page={"page_sponsors"} adminComponentsVisible={adminComponentsVisible} deleteMode={deleteMode} />}exact  />
                  <Route path="/training" element={<Training page={"page_training"} adminComponentsVisible={adminComponentsVisible} deleteMode={deleteMode} />}exact  />
                  <Route path="/users" element={<UserMgmt />} exact />
                  <Route path="/*" element={<Welcome page={"page_welcome"} adminComponentsVisible={adminComponentsVisible} deleteMode={deleteMode} />}exact  />
                </Routes>
              </BrowserRouter>
            </div>
            <Footer />
          </>
          :
          <Auth />
        }
      </IntlProvider>
    </React.StrictMode>
  );
}

root.render(<App />);
reportWebVitals();